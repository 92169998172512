<template>
    <v-container class="px-0 mr-0">
        <v-skeleton-loader ref="skeleton" type="table" v-if="isFirstLoading">

        </v-skeleton-loader>
        <v-data-table
        v-if="!isFirstLoading||data!=null"
        :items="filteredCompanies"
        :headers="dataHeaders"
        :search = "searchField"
        item-key="user_id"
        :show-select="showSelect"
        v-model="selectedUser"
        @input="updateUserId"
        :footer-props="{
            showFirstLastPage:true,
        }"
        class="elevation-1 mt-5">
        <template v-slot:item.Index="{item}">
            {{ data.indexOf(item)+1 }}
        </template>
        <template v-slot:item.name="{item}">
            <!-- <a @click="redirectToCompany(data[data.indexOf(item)].companies_id)" class="text-decoration-none">
            {{ item.name }}
            </a> -->
            <router-link :to="({name:'PageCompaniesDetail',params:{id:data[data.indexOf(item)].companies_id}})">
                {{ item.name }}
            </router-link>
        </template>
        <template v-slot:item.created_at="{item}">
            <span v-if="item.created_at!=null">
                {{ item.created_at.split(" ")[0] }}
                {{ convertTimeZone(item.created_at).split(" ")[3]}} {{ convertTimeZone(item.created_at).split(" ")[4] }}
            </span>
            <span
                v-else>
                -
            </span>
        </template>
        <template v-slot:item.welcome_guide_sent="{item}">
            <span v-if="item.welcome_guide_sent!=null">
                {{ item.welcome_guide_sent.split(" ")[0] }}
                {{ convertTimeZone(item.welcome_guide_sent).split(" ")[3]}} {{ convertTimeZone(item.welcome_guide_sent).split(" ")[4] }}
            </span>
            <span
                v-else>
                -
            </span>
        </template>
        <template
            v-slot:item.cs_pic="{item}">
            <span
                v-if="item.cs_id!=null">
                {{ item.cs_fname+" "+item.cs_lname}}
            </span>
            <span
                v-else>
                -
            </span>
        </template>
        <template v-slot:top>
                <v-toolbar width="auto" height="auto" color=" py-3" flat>
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <!-- <v-icon large color="black" class="px-2">
                                    fa-user-circle
                                </v-icon> -->
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                    Users
                                </v-toolbar-title>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col
                                    class="px-0 pb-0">
                                    <div>
                                        <v-text-field prepend-inner-icon="mdi-calendar" v-model="startDate" style="border-radius:4px 0px 0px 4px" @click:prepend-inner="()=>{
                                            isStartDate = true;
                                        }" label="Start date" @click="()=>{
                                            isStartDate = true;
                                        }" dense outlined>
        
                                        </v-text-field>
                                        <v-dialog v-model="isStartDate" height="auto" width="auto">
                                            <v-card class="pa-3">
                                                <p>
                                                    Start Date
                                                </p>
                                                <v-date-picker flat v-model="startDate" :landscape="true" :reactive="true">
        
                                                </v-date-picker>
                                                <slot name="footer">
                                                    <div class="d-flex justify-end white" >
                                                        <v-btn color="primary" plain @click="()=>{
                                                            isStartDate=false;
                                                            startDate = null;
                                                        }">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn color="primary" @click="()=>{
                                                            isStartDate = false;
                                                        }">
                                                            Confirm
                                                        </v-btn>
                                                    </div>
        
                                                </slot>
                                            </v-card>
                                        </v-dialog>
                                    </div>
                                </v-col>
                                <v-col
                                    class="px-0 pb-0">
                                    <div>
                                        <v-text-field @click:prepend-inner="()=>{
                                            isEndDate = true;
                                        }" prepend-inner-icon="mdi-calendar" @click="()=>{
                                            isEndDate = true;
                                        }" style="border-radius:0px" label="End Date" v-model="endDate"  dense outlined>
        
                                        </v-text-field>
                                        <v-dialog v-model="isEndDate" height="auto" width="auto">
                                            <v-card class="pa-3">
                                                <p>
                                                    End Date
                                                </p>
                                                <v-date-picker flat v-model="endDate" :landscape="true" :reactive="true">
                                                </v-date-picker>
                                                <div name="footer" class="footer">
                                                    <div class="d-flex justify-end my-3">
                                                        <v-btn color="primary" plain @click="()=>{
                                                            isEndDate = false;
                                                            endDate = null;
                                                        }">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn color="primary" @click="()=>{
                                                            isEndDate = false;
                                                        }">
                                                            Confirm
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </v-card>
                                        </v-dialog>
        
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    class="px-0">
                                    <v-autocomplete
                                    :items="csOptions"
                                    v-model="selectedCS"
                                    dense 
                                    outlined
                                    clearable
                                    label="Customer service"
                                    item-text="username"
                                    item-value="id">

                                    </v-autocomplete>
                                </v-col>
                                
                            </v-row>
                            <v-row>
                                <v-col
                                    class="pt-0 mb-4"
                                    align-self="right">
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        color="primary"
                                        @click="resetFilter"
                                        plain>
                                        Reset
                                    </v-btn>
                                    <v-btn
                                        @click="filterCompaniesSubscriptionDate"
                                        color="primary">
                                        Filter
                                    </v-btn>
                                </div>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3">
                                <v-text-field
                                dense
                                outlined
                                clearable
                                v-model="searchField"
                                label="Search">
                                </v-text-field>
                            </v-row>
                            <div class="black--text">
                                Total users : {{ totalData }}
                            </div>
                        </v-col>
                       
                    </v-row>
                </v-toolbar>
            </template>
        </v-data-table>
    </v-container>
</template>
<script>
export default{
    props:[
        'userId'
    ],

    data: ()=>({
        startDate:null,
        endDate:null,
        isStartDate:false,
        isEndDate:false,
        isFirstLoading:true,
        selectedCS:null,
        searchField:null,
        data:null,
        totalData:null,
        filteredCompanies:null,
        selectedUser:[],
        csOptions:null,
        dataHeaders:[
        {
            text:"#",
            value:"Index",
            align:"start",
        },
        {
            text:'Name',
            value:'user_name',
        },
        {
            text:'Email',
            value:'email',
        },
        {
            text:'No. phone',
            value:'mobile',
        },
        {
            text:'Company',
            value:'name'
        },
        {
            text:'CS PIC',
            value:'cs_pic'
        },
        {
            text:'Created date',
            value:'created_at',
        },
        {
            text:'Welcome guide sent date',
            value:'welcome_guide_sent'
        },
        {
            text:'Welcome whatssap sent date',
            value:'welcome_wa_sent'
        }
        ],
        showSelect:true,
        api:{
            isSucessful : false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getClient"){
                this.data = resp.data.users;
                this.filteredCompanies = this.$_.clone(resp.data.users);
                this.totalData = resp.data.total;
                this.isFirstLoading = false;
              
            }
            if(resp.class==='getStaffListByRole') {
                this.csOptions = resp.data;
                let blankOptions = {
                    'username':'-',
                    'value':'-'
                }
                this.csOptions.push(blankOptions);

            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted(){
        this.fetch();
    },
    methods:{   
        fetch(){    
            let fetchCompaniesUsersApi = this.fetchCompanyUsers();
            this.$axios.all([
                this.$api.fetch(fetchCompaniesUsersApi),
                this.$api.fetch(this.fetchCSOptions()),

            ])

        },
        fetchCompanyUsers(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/client";

            if(this.$store.getters.getRole=='sales_team_lead' || this.$store.getters.getRole=='salesperson'||this.$store.getters.getRole=='marketing'){
                tempApi.url = process.env.VUE_APP_SERVER_API+"/users/client?restricted=true&userId="+this.$store.getters.getUserId;
            }


            return tempApi;
        },
        fetchCSOptions() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/role/staff/customer_service";
            return tempApi;
        },
        redirectToCompany(companyId){
            let route = this.$router.resolve({name:'PageCompaniesDetail',params:{'id':companyId}});
            window.open(route.href);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
        },
        updateUserId() {
            this.$emit('selectUser',this.selectedUser);
        },
        resetFilter() {
            this.startDate = null;
            this.endDate = null;
            this.selectedCS =null;
            this.filterCompaniesSubscriptionDate();
        },
        filterCompaniesSubscriptionDate() {
            let tempCompanies = this.$_.clone(this.data);
            let startDate = this.$_.clone(this.startDate);
            let endDate = this.$_.clone(this.endDate);
            let filterCS = this.$_.clone(this.selectedCS);

            if(startDate != null) {
                tempCompanies = tempCompanies.filter(function(item) {
                    let tempCreatedDate = (item.created_at !=null )?item.created_at.split(" ")[0]:null;
                    if(tempCreatedDate >= startDate) {
                        return true;
                    }
                })
            }
            if(endDate != null) {
                tempCompanies = tempCompanies.filter(function(item) {
                    let tempExpiredDate = (item.created_at!=null)?item.created_at.split(" ")[0]:null;
                    if(tempExpiredDate<=endDate) {
                        return true;
                    }
                })
            }
            if(filterCS != null) {
              
                tempCompanies = tempCompanies.filter(function(item) {
                    if(filterCS=='-' && item.cs_id == null) {
                        return true;
                    }
                    if(item.cs_id !=null && item.cs_id==filterCS) {
                        return true;
                    }
                })
            }
            this.filteredCompanies = tempCompanies;
        }
    }
}


</script>