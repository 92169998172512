<template>
    <v-container>
        <AConfirmation
            :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="this.api"></ASuccessFour>
        <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading"></v-skeleton-loader>
        <v-row class="mt-3" v-else>
            <v-col align="center" justify="center" cols="12" sm="12" class="mx-auto">
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar class="secondary white--text text-h5">
                        Send Email
                    </v-toolbar>
                    <div class="mt-4 pt-3 px-3">
                        <div class="d-flex justify-start">
                            <v-autocomplete :items="emailOptions" item-text="text" return-object v-model="selectedEmail" dense outlined label="Email Template">

                            </v-autocomplete>
                        </div>
                        <div class="d-flex justify-end">
                            <v-btn color="primary" plain @click="()=>{
                                $router.go(-1);
                            }">
                                Cancel
                            </v-btn>
                            <v-btn :loading="api.isLoading" color="primary" @click="()=>{
                                isPending = true;
                            }">
                                Confirm
                            </v-btn>
                        </div>

                    </div>
                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../common/AConfirmation.vue';
import ASuccessFour from '../common/ASuccessFour.vue';
export default {
    components:{
        AConfirmation,
        ASuccessFour,
},
    computed: mapState({
    //
    }),
    props:[
        'userId'
    ],
    data: () => ({
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            },
        client:null,
        selectedClient:null,
        selectedEmail:null,
        emailOptions:[
            {
                text:'Reset Password',
                value:1,
            },
            {
                text:'Welcome Guide',
                value:2,
            },
            {
                text:'Verify Account',
                value:3,
            }
        ]
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            
            if(resp.class==="sendMultipleEmail"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Send The Email";
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
    },
    methods: {
        fetch(){
           

        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            this.$api.fetch(this.sendMultipleEmail());

        },
        sendMultipleEmail() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/email/send/multiple";
            let formData = new FormData;
            this.userId.forEach(item=>{
                formData.append('userId[]',item.user_id);
            });
            formData.append('template',this.selectedEmail.value);
            tempApi.params = formData;
            return tempApi;
        },
        sendEmail(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/email/send";
            let formData = new FormData;
            formData.append('id',this.selectedClient.user_id);
            formData.append('template',this.selectedEmail.value);
            tempApi.params = formData;
            return tempApi;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){

        },
    }
}
</script>