var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"background-color":"white"}},[_c('ASuccessWithoutReload',{attrs:{"api":this.api},on:{"close":_vm.closeSuccessModal}}),_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.submit}}),(_vm.api.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12"}},[_c('v-card',{staticClass:"py-4",attrs:{"outlined":"","elevatio":"1"}},[_c('v-toolbar',{staticClass:"secondary ma-4"},[_c('v-toolbar-title',{staticClass:"white--text text-h4"},[_vm._v(" Onboarding ")])],1),_c('v-container',[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',{},[_c('ComponentCSField',{attrs:{"fieldValue":_vm.form.cs_pic},on:{"updateData":function (data){
                                        _vm.form.cs_pic=data
                                    }}})],1),_c('div',{},[_c('ComponentDateModal',{attrs:{"label":"Welcome guide sent date","data":this.form.welcome_guide_sent},on:{"updateDateData":function (data){
                                        this$1.form.welcome_guide_sent = data;
                                    }}})],1),_c('div',{},[_c('ComponentDateModal',{attrs:{"label":"Welcome whatssap send date","data":this.form.welcome_wa_sent},on:{"updateDateData":function (data){
                                        this$1.form.welcome_wa_sent = data;
                                    }}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","plain":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.validateInput}},[_vm._v(" Submit ")])],1),_c('div',{staticClass:"mt-3"},[_c('AError',{attrs:{"api":this.api}})],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }