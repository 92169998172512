<template>
    <v-container
        style="background-color:white">
        <ASuccessWithoutReload
            :api="this.api"
            @close="closeSuccessModal"
        />
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader
            v-if="api.isLoading" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row
            v-else>
            <v-col
                class="pa-0"
                cols="12"
                sm="12">
                <v-card
                    outlined
                    elevatio="1"
                    class="py-4">
                    <v-toolbar
                        class="secondary ma-4">
                        <v-toolbar-title
                            class="white--text text-h4">
                            Onboarding
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-container>
                        <v-row
                            class="mx-2">
                            <v-col
                            cols="12"
                            sm="12">
                                <div
                                    class="">
                                    <ComponentCSField
                                        :fieldValue="form.cs_pic"
                                        @updateData="(data)=>{
                                            form.cs_pic=data
                                        }"/>
                                </div>
                                <div
                                    class="">
                                    <ComponentDateModal
                                        label="Welcome guide sent date"
                                        :data="this.form.welcome_guide_sent"
                                        @updateDateData="(data)=>{
                                            this.form.welcome_guide_sent = data;
                                        }"/>
                                </div>
                                <div
                                    class="">
                                    <ComponentDateModal
                                        label="Welcome whatssap send date"
                                        :data="this.form.welcome_wa_sent"
                                        @updateDateData="(data)=>{
                                            this.form.welcome_wa_sent = data;
                                        }"/>
                                </div>
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        @click="closeModal"
                                        color="primary"
                                        plain>
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        @click="validateInput"
                                        color="primary">
                                        Submit
                                    </v-btn>
                                </div>
                                <div
                                    class="mt-3">
                                    <AError :api="this.api"/>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentDateModal from '../date/ComponentDateModal.vue';
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
import AConfirmation from '../common/AConfirmation.vue';
import ComponentCSField from '../company/field/ComponentCSField.vue';
export default {
    components:{
    AConfirmation,
    ComponentCSField,
    ComponentDateModal,
    ASuccessWithoutReload,
},
    computed: mapState({
    //
    }),
    props:[
        'userId'
    ],
    data: () => ({
        isPending:false,
        form:{
            welcome_wa_sent:null,
            welcome_guide_sent:null,
            cs_pic:null,
        },
        optionHRDCStatus:[
            "Year 1",
            "Renewal - Year 2",
            "Renewal - Year 3",
        ],
        data:{
            status:null,
        },
        api:
        {
            isLoading : false,
            isError:false,
            error:null,
            url:null,
            isSuccesful:false,
            success:null,
        },
       
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="createUserOnboardingBatch") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully create user onboarding";

            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    },
    methods: {
        fetch(){
        },
        
        validateInput(){
            if(this.form.cs_pic ===null && this.form.welcome_guide_sent===null && this.form.welcome_wa_sent===null) {
                this.api.isError = true;
                this.api.error = "Please fill in at least 1 field above";
                return true;
            }
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            // if(!Array.isArray(this.companyId)) {
            //     this.$api.fetch(this.create());
            //     return 1;
            // }
            this.$api.fetch(this.createMultipleForm());

        },
        cancelSubmit(){
            this.isPending = false;
        },
        createMultipleForm() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/onboarding/batch";
            let formData = new FormData;
            try {
                if(this.userId == null || this.userId == 'undefined') {
                    throw new Error("Please select any users first");
                }
                this.userId.forEach((element)=>{
                    formData.append('userId[]',element.user_id);
                });
                if(this.form.welcome_guide_sent!=null) {
                    formData.append('welcome_wa_sent',this.form.welcome_wa_sent);
                }
                if(this.form.welcome_wa_sent!=null) {
                    formData.append('welcome_guide_sent',this.form.welcome_guide_sent);
                }
                if(this.form.cs_pic!=null) {
                    formData.append('cs_pic',this.form.cs_pic.id);
                }
                tempApi.params = formData;
                return tempApi;
                
            }catch(error) {
                this.api.isError = true;
                this.api.error = error;
            }
                
        },
        updateDataPaymentDate(data) {
            this.form.subscriptionPaymentDate = data;
        },
        convertTimeToDay(time) {
            return time.split(" ")[0];
        },
        closeModal() {
            this.$emit("close")
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
            this.$emit('finish');
        }
    }
}
</script>