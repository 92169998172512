<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-row>
            <v-col>
                <!-- <v-row>
                    <span
                        class="text-h5 my-2">
                        Form 
                    </span>
                </v-row> -->
                <v-row>
                    <v-autocomplete
                        dense
                        outlined 
                        :items="formType"
                        label="Please select the form"
                        v-model="formSelected"
                        return-object>
                    </v-autocomplete>
                </v-row>
                <v-row>
                    <component
                        :is="formSelected.value"
                        :userId="userId">
                    </component>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
import ComponentAddUserOnboarding from '../../components/form/ComponentAddUserOnboarding.vue';
import ComponentSendEmail from '../../components/email/ComponentSendEmail.vue';
export default {
    components:{
    ASuccessWithoutReload,
    ComponentAddUserOnboarding,
    ComponentSendEmail,
},
    computed: mapState({
    //
}),
    props:[
        'userId'
    ],
    data: () => ({
        search:"",
        data:null,
        showSelect:true,
        formSelected:{
            text:"Onboarding",
            value:'ComponentAddUserOnboarding',
        },
        formType:[
            {
                text:'Onboarding',
                value:'ComponentAddUserOnboarding',
            },
            {
                text:"Send email",
                value:'ComponentSendEmail',
            },
            
        ],
        dataHeader:[
           
            {
                text:'Id',
                value:'company_id'
            },
            {
                text:'Company',
                value:'name'
            },
            {
                text:'Status',
                value:'verification',
            },
           

        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="GetListOfCompanies") {
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchCompanies());
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function
        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        }
    }
}
</script>